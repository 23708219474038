<template>
  <div>
    <h4>SEGUMIENTO PROFORMAS NO INICIADAS</h4>
    <app-ctrl-year-month
      v-model="date"
      @input="getList()"
    ></app-ctrl-year-month>
    <table class="table table-bordered table-sm">
      <thead>
        <tr>
          <th>DOCTOR</th>
          <th>PACIENTE</th>
          <th>TELEFONO</th>
          <th>PROXIMA CITA</th>
          <!-- <th>FECHA INICIO</th>
          
          <th>AVANCE</th> -->
        </tr>
      </thead>
      <tbody>
        <template v-for="(l, i) in list">
          <tr :key="i">
            <td>{{ l.employee_name }}</td>
            <td>{{ l.client_name }}</td>
            <td>{{ l.phone_number }}</td>
            <td>
              <span v-show="l.next_date">{{ l.next_date | date }}</span>
              <span v-show="!l.next_date">No tiene cita</span>
            </td>
            <td class="p-0">
              <button
                class="btn btn-sm btn-light"
                @click="
                  l.see_comment = !l.see_comment;
                  $forceUpdate();
                "
              >
                <i class="fa fa-comment"></i>
              </button>
            </td>
            <!-- <td>{{ l.initiated_date | dateTime }}</td>
          
          <td>{{ l.progress | percent }}</td> -->
          </tr>
          <tr :key="`a${i}`">
            <td colspan="5" v-if="l.see_comment">
              <app-comments
                :url="`dental_client_${l.client_id}`"
              ></app-comments>
            </td>
          </tr>
        </template>
        <tr>
          <th>TOTAL</th>
          <th>{{ list.length }}</th>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { DentalClinicService } from "../service";
export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    date: {},
    list: []
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    getList() {
      DentalClinicService.getReportProform({
        ...this.date,
        used: 0,
        per_page: 10000
      }).then(res => {
        this.list = res;
      });
    }
  }
};
</script>

<style></style>
